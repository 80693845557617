import { z } from 'zod';

import i18n from '@/i18n';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export const UserAbroadCreateSchema = z
    .object({
        name: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
        street: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
        zip: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
        city: z.string().min(2, i18n.global.t('Hetta er ein kravdur teigur.')),
        country_code: z.string(),
        phone: z
            .string()
            .min(2, i18n.global.t('Hetta er ein kravdur teigur.'))
            .regex(phoneRegex, i18n.global.t('Vinaliga skriva eitt rætt telefonnummar.')),
        phone_code: z.string(),
        email: z.string().email(i18n.global.t('Vinaliga skriva ein rættan teldubústað.')),
        language: z.string(),
        password: z.string().min(12, i18n.global.t('Loyniorðið skal vera minst 12 tekin langt.')),
        password_confirmation: z.string().min(12, i18n.global.t('Loyniorðið skal vera minst 12 tekin langt.')),
    })
    .superRefine(({ password, password_confirmation }, checkPassComplexity) => {
        const containsLetter = (ch: string) => /[a-zA-Z]/.test(ch);

        let countOfLetters = 0;
        let countOfNumbers = 0;

        for (let i = 0; i < password.length; i++) {
            const character = password.charAt(i);

            if (!isNaN(+character)) {
                countOfNumbers++;
            } else if (containsLetter(character)) {
                countOfLetters++;
            }
        }

        if (countOfLetters < 1 || countOfNumbers < 1) {
            checkPassComplexity.addIssue({
                code: 'custom',
                message: i18n.global.t('Loyniorðið skal innihalda bæði stavir og í minsta lagi eitt tal.'),
            });
        }

        countOfLetters = 0;
        countOfNumbers = 0;

        for (let i = 0; i < password_confirmation.length; i++) {
            const character = password_confirmation.charAt(i);

            if (!isNaN(+character)) {
                countOfNumbers++;
            } else if (containsLetter(character)) {
                countOfLetters++;
            }
        }

        if (countOfLetters < 1 || countOfNumbers < 1) {
            checkPassComplexity.addIssue({
                code: 'custom',
                message: i18n.global.t('Vátta loyniorðið skal innihalda bæði stavir og í minsta lagi eitt tal.'),
            });
        }

        if (password !== password_confirmation) {
            checkPassComplexity.addIssue({
                code: 'custom',
                message: i18n.global.t('Loyniorðini skullu vera eins.'),
            });
        }
    });
