import { z } from 'zod';

import i18n from '@/i18n';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export const PhoneVerifySchema = z.object({
    phone: z
        .string()
        .min(2, i18n.global.t('Hetta er ein kravdur teigur.'))
        .regex(phoneRegex, i18n.global.t('Vinaliga skriva eitt rætt telefonnummar.')),
    phone_code: z.string().optional(),
    code: z.string().length(6, i18n.global.t('Kotan skal vera 6 tekin.')),
});
